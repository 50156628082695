import React, { Component } from 'react';
import SignaturePad from 'react-signature-canvas';
import styles from './WaybillSignature.module.scss';
import Button from '../../input/Button';
import PropTypes from 'prop-types';
import Moment from 'moment';

class WaybillSignature extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    signatureDataURL: undefined,
    timestamp: undefined,
  };
  sigPad = {};

  clear = () => {
    this.sigPad.clear();
    this.setState({
      signatureDataURL: undefined,
      timestamp: undefined
    });
  };

  setSignature = () => {
    const signatureDataURL = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    const timestamp = Moment().format('DD.MM.YYYY HH:mm');

    this.setState({
      signatureDataURL: signatureDataURL,
      timestamp: timestamp
    });

    this.props.setSignatureData(signatureDataURL, timestamp);
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.sigContainer}>
          <SignaturePad
            canvasProps={{className: styles.sigPad}}
            clearOnResize={false}
            ref={(ref) => {
              this.sigPad = ref;
            }}
            onEnd={this.setSignature}
          />
        </div>
        <div>
          <Button
            className={styles.emptyButton} exception
            onClick={this.clear}
          >
            Tyhjennä
          </Button>
        </div>
      </div>
    );
  }
}

WaybillSignature.propTypes = {
  setSignatureData: PropTypes.func.isRequired
};

export default WaybillSignature;
