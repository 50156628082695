import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './WaybillOrderTransactionsSummary.module.scss';
import Panel from '../../layout/Panel';
import {
  routeExceptionCodeToLabel,
  routeExceptionStatusCodeToLabel,
  RouteExceptionStatusTypeTranslations,
} from '../utils/types';

class WaybillExceptions extends Component {
  constructor(props) {
    super(props);
  }

  renderException = (exception, index) => {
    const place = exception.place;
    const time = exception.time;
    const exceptionLabel = routeExceptionCodeToLabel(exception.code);
    const exceptionStatus = routeExceptionStatusCodeToLabel(exception.status);
    const exceptionStatusTranslated = RouteExceptionStatusTypeTranslations[exceptionStatus];
    const exceptionInfo = exception.info;
    return (
      <div key={index} className={styles.exceptionItem}>
        {index}: {exceptionLabel}, {time}, {place}, {exceptionStatusTranslated} {exceptionInfo}
      </div>
    );
  };

  render() {
    const { exceptions } = this.props;

    return (
      <Panel className={styles.panel}>
        <b>Poikkeamat/Lisämaksulliset palvelut</b>
        <div className={styles.exceptionsList}>
          {
            exceptions.length > 0
              ? exceptions.map(this.renderException)
              : <div>Ei poikkeamia.</div>
          }
        </div>
      </Panel>
    );
  }
}

WaybillExceptions.propTypes = {
  exceptions: PropTypes.array,
  routeId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default WaybillExceptions;
