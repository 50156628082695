import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrderDetails.module.scss';

const OrderDetails = ({ order, children }) => (
  <div className={styles.root}>
    <header>
      <h2>VR:n tilausnumero {order.orderNum}</h2>

      <div className={styles.orderSummaryGrid}>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>As tilausnumero</span>
          <span className={styles.number}>{order.customerOrderNum || '-'}</span>
        </div>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>Asiakkaan viite</span>
          <span className={styles.number}>{order.customerRefNum || '-'}</span>
        </div>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>Tilausviite</span>
          <span className={styles.number}>{order.orderRefNum || '-'}</span>
        </div>
        <div className={styles.gridElement}>
          <span className={styles.gridTitle}>Toimitustapa</span>
          <span className={styles.number}>{order.delivery || '-'}</span>
        </div>
      </div>
    </header>
    {children}
  </div>
);

OrderDetails.propTypes = {
  order: PropTypes.shape({
    orderNum: PropTypes.string.isRequired,
    customerOrderNum: PropTypes.string.isRequired,
    customerRefNum: PropTypes.string,
    orderRefNum: PropTypes.string,
    delivery: PropTypes.string
  }).isRequired,
  children: PropTypes.node
};

export default OrderDetails;
