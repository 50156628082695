import React from 'react';
import PropTypes from 'prop-types';
import styles from './WaybillOrderTransaction.module.scss';

const WaybillOrderTransaction = props => {

  return (
    <div className={styles.root}>
      <div className={styles.dataTable}>
        <span className={styles.dataProductName}>{props.commodityName}</span>
        <span className={styles.dataAmountLoaded}>
          {`${
            !isNaN(props.actualAmount)
              ? Number(props.actualAmount).toLocaleString('fi-FI')
              : '-'
          } ${props.actualUnit}`}
        </span>
        <span className={styles.dataAmount}>
          {`${
            !isNaN(props.orderedAmount)
              ? Number(props.orderedAmount).toLocaleString('fi-FI')
              : '-'
          } ${props.orderedUnit}`}
        </span>
        <span className={styles.dataWeight}>
          {
            props.weight
              ? `${Number(props.weight).toLocaleString('fi-FI')} kg`
              : '–'
          }
        </span>
        <span className={styles.dataItems}>
          {props.items ? props.items : '–'}
        </span>
        <span className={styles.dataDimensions}>
          {props.width && props.height && props.length
            ? `${props.width} x ${props.height} x ${props.length}`
            : '–'}
        </span>
      </div>
    </div>
  );
};

WaybillOrderTransaction.propTypes = {
  // transaction
  actualAmount: PropTypes.number,
  actualUnit: PropTypes.string,
  orderedAmount: PropTypes.number.isRequired,
  orderedUnit: PropTypes.string.isRequired,
  pickupTransactionId: PropTypes.string.isRequired,
  unloadTransactionId: PropTypes.string.isRequired,
  receiver: PropTypes.string.isRequired,
  unloadAddress: PropTypes.string,
  unloadAddressAdditionalInfo: PropTypes.string,
  unloadCity: PropTypes.string.isRequired,
  unloadPerson: PropTypes.string,
  unloadPhone: PropTypes.string,
  unloadZipcode: PropTypes.string,
  waybillNum: PropTypes.string,
  weightNoteNumberLoading: PropTypes.string,
  weightNoteNumberUnloading: PropTypes.string,
  container1Load: PropTypes.string,
  container2Load: PropTypes.string,
  container3Load: PropTypes.string,
  container4Load: PropTypes.string,
  container1Unload: PropTypes.string,
  container2Unload: PropTypes.string,
  container3Unload: PropTypes.string,
  container4Unload: PropTypes.string,

  // row
  additionalInfo: PropTypes.array,
  commodityName: PropTypes.string.isRequired,
  items: PropTypes.number,
  height: PropTypes.number,
  length: PropTypes.number,
  weight: PropTypes.number,
  width: PropTypes.number,

  // other
  allowActions: PropTypes.bool.isRequired,
  isOngoingLoad: PropTypes.bool.isRequired,
  isOngoingUnload: PropTypes.bool.isRequired,
  onFormCreated: PropTypes.func.isRequired,
  onCopyWaybill: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default WaybillOrderTransaction;
