import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import styles from './WaybillOrderTransactionsSummary.module.scss';

const WaybillOrderTransactionsSummary = ({ numRows }) => (
  <div className={styles.root}>
    <h2>
      Kuljetusasiakirjan yhteenveto ({Moment().format('DD.MM.YYYY HH:mm:ss')})
    </h2>
    <div>
      Tilauksella {`${numRows} ${numRows > 1 ? ' tilausriviä' : ' tilausrivi'}`}
    </div>
  </div>
);

WaybillOrderTransactionsSummary.propTypes = {
  numRows: PropTypes.number.isRequired
};

export default WaybillOrderTransactionsSummary;
